import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EventDurationPicker = ({ onDateRangeChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (onDateRangeChange) {
      onDateRangeChange({ startDate: start, endDate: end });
    }
  };

  return (
    <div>
      <DatePicker
        id="event-duration"
        selected={startDate}
        onChange={handleChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        placeholderText="Select Dates"
        isClearable={true}
      />
    </div>
  );
};

export default EventDurationPicker;
