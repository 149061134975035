import React, { useState } from 'react';
import { firestore, setDoc, getDoc, doc } from './firebase-config';
import './RequestInfoForm.css';

function RequestInfoForm({ onClose }) {
  const [showForm, setShowForm] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [message, setMessage] = useState('');


  const handleCloseForm = () => {
    setShowForm(false);
    setFormData({ name: '', email: '', message: '' });
    setMessage('');
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
  
    const createdAt = new Date();
    const docId = createdAt.toISOString();
    const eventDocRef = doc(firestore, 'requests', docId);

    try {
      const eventDoc = await getDoc(eventDocRef);
      if (eventDoc.exists()) {
        setMessage('Event with this name already exists. Please choose a different name.');
        return;
      }
      await setDoc(eventDocRef, {
        ...formData,
        createdAt: new Date(),
      });
      setMessage('Request submitted!');
    } catch (error) {
      setMessage('Error submitting request. Please try again.');
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div>
      {showForm && (
        <div className="request-info-overlay" onClick={onClose}>
          <div className="request-info-form" onClick={(e) => e.stopPropagation()}>
            <button className="close-form-button" onClick={onClose}>
            <span class="material-symbols-outlined">
              close
            </span>
            </button>
            <form onSubmit={handleSubmit}>
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleFormChange}
                required
              />

              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleFormChange}
                required
              />

              <label>Message:</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleFormChange}
                required
              />

              <button className="submit-button" type="submit">
                Submit
              </button>
              {message && <p className="submit-message">{message}</p>}
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
export default RequestInfoForm;
