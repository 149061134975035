import { useState } from 'react';


export const useQuickPayCheckout = (price) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const amount = price*100;

  const handleQuickPayCheckout = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch('https://us-central1-vip-feed-d30d0.cloudfunctions.net/createQuickPayCheckout', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          amount: amount,
          redirectUrl: 'https://www.snappo.io/reservations',
        }),
      });
      const data = await response.json();
      console.log(data);
      if (data.checkoutUrl) {
        window.location.href = data.checkoutUrl; // Redirect to Square's hosted checkout page
      } else {
        setError('Failed to generate checkout link.');
      }
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  return { loading, error, handleQuickPayCheckout };
};