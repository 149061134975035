import React, { useState, useEffect } from 'react';
import { firestore, setDoc, getDoc, doc } from './firebase-config';
import './BookNow.css';
import { useQuickPayCheckout } from './QuickPayCheckoutButton';
import { useLocation } from "react-router-dom";
import EventDurationPicker from './EventDurationPicker';
import { differenceInDays } from 'date-fns';
import RequestInfoForm from './RequestInfoForm';

const BookNow = () => {

  const images = [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/vip-feed-d30d0.appspot.com/o/FCMImages%2Fcollage_features.png?alt=media&token=4464c25a-ecf0-4b1d-9d1e-a249b25d108b",
      alt: "Collage Features"
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/vip-feed-d30d0.appspot.com/o/FCMImages%2Ffeatures_image.png?alt=media&token=5e7d90dc-058b-45df-af45-f5684e2fbb43",
      alt: "Feature Image"
    }
  ];

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("orderId");
  const [productOptions, setProductOptions] = useState(null);

  const basePrice = 199.00;
  const [duration, setDuration] = useState(0);

  // const [additionalFeed, setAdditionalFeed] = useState('no-add-feed');
  const [additionalFeed] = useState('no-add-feed');

  const [guests, setGuests] = useState('50-guests');
  const [price, setPrice] = useState(basePrice);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const [message, setMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(images[0].src);
  const {loading, handleQuickPayCheckout } = useQuickPayCheckout(price);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  const durationLabels = {
    "1-7-days": "1-7 Days",
    "7-14-days": "7-14 Days",
  };
  
  // const additionalFeedLabels = {
  //   "no-add-feed": "No Additional Event",
  //   "1-add-feed": "1 Additional Event",
  //   "2-add-feed": "2 Additional Event",
  //   "3-add-feed": "3 Additional Event",
  // };
  
  const guestsLabels = {
    "50-guests": "50+ Guests",
    "100-guests": "100+ Guests",
    "200-guests": "200+ Guests",
    "300-guests": "300+ Guests",
  };
  

  useEffect(() => {
    const optionsString = localStorage.getItem("productOptions");
    if (optionsString) {
      try {
        setProductOptions(JSON.parse(optionsString));
      } catch (err) {
        console.error("Error parsing product options:", err);
      }
    }
  }, []);


  useEffect(() => {
    async function addPaymentToFirestore() {
      if (!orderId || !productOptions) return;
      try {
        const createdAt = new Date();
        const paymentDocRef = doc(firestore, "payments", orderId);
        await setDoc(paymentDocRef, {
          ...productOptions,
          orderId,
          createdAt,
        });
        console.log("Document successfully written to Firestore.");
      } catch (error) {
        console.error("Error writing document to Firestore:", error);
      }
    }
    addPaymentToFirestore();
  }, [orderId, productOptions]);


  const calculatePrice = (selectedDuration, selectedFeed, selectedGuests) => {
    let updatedPrice = basePrice;
    
    if (selectedDuration) updatedPrice += selectedDuration;
    
    if (selectedFeed === '1-add-feed') updatedPrice += 30;
    if (selectedFeed === '2-add-feed') updatedPrice += 60;
    if (selectedFeed === '3-add-feed') updatedPrice += 90;
    
    if (selectedGuests === '100-guests') updatedPrice += 20;
    if (selectedGuests === '200-guests') updatedPrice += 40;
    if (selectedGuests === '300-guests') updatedPrice += 60;
    
    setPrice(updatedPrice);
  };

  // Handlers for dropdown changes
  const handleDurationChange = (range) => {
    setDateRange(range);
    if (range.startDate && range.endDate) {
      const days = differenceInDays(range.endDate, range.startDate) + 1;
      //console.log('Selected duration (days):', days);
      
      let extraCost = 0;
      if (days <= 7) {
        extraCost = 0;
      } else if (days > 7 && days <= 14) {
        extraCost = 50;
      } else if (days >= 15) {
        extraCost = 100;
      }
      setDuration(extraCost);
      calculatePrice(extraCost, additionalFeed, guests);
    }
  };
  
  // const handleFeedChange = (e) => {
  //   setAdditionalFeed(e.target.value);
  //   calculatePrice(duration, e.target.value, guests);
  // };

  const handleGuestsChange = (e) => {
    setGuests(e.target.value);
    calculatePrice(duration, additionalFeed, e.target.value);
  };

  const handleRequestInfo = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleProcessPayment = async (e) => {
    e.preventDefault();
    setMessage('');
    const options = { dateRange, duration, additionalFeed, guests, price };
    console.log("options:", options);
    console.log("date range:", dateRange);
    localStorage.setItem("productOptions", JSON.stringify(options));

    try {
      await handleQuickPayCheckout();
    } catch (error) {
      setMessage('Error processing payment or submitting reservation. Please try again.');
      console.error('Error:', error);
    }
  };

  return (
    <main className="product-page">
      <section className="product-details">
        <section className="product-gallery">
          <div className="main-image-container">
            <img src={selectedImage} alt="Selected" className="main-product-image" />
          </div>
          <div className="thumbnail-container">
            {images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={image.alt}
                className={`thumbnail ${selectedImage === image.src ? 'active' : ''}`}
                onClick={() => setSelectedImage(image.src)}
              />
            ))}
          </div>
        </section>

        <h1 className="product-title">Custom Private Event Package</h1>
        <div className="price-rating">
          {price === basePrice ? (
              <div className="base-price">Starting at ${basePrice.toFixed(2)}</div>
            ) : (
              <div className="product-price">${price.toFixed(2)}</div>
            )}
        </div>

        <form className="product-options">
          <label htmlFor="event-duration">Event Duration</label>
          <EventDurationPicker onDateRangeChange={handleDurationChange} />

          <label htmlFor="guests">Number of Guests</label>
          <select id="guests" value={guests} onChange={handleGuestsChange}>
            <option value="50-guests">50+ Guests</option>
            <option value="100-guests">100+ Guests</option>
            <option value="200-guests">200+ Guests</option>
            <option value="300-guests">300+ Guests</option>
          </select>

          {/* <label htmlFor="additional-feed">Additional Events</label>
          <select id="additional-feed" value={additionalFeed} onChange={handleFeedChange}>
            <option value="no-add-feed">No Additional Event</option>
            <option value="1-add-feed">1 Additional Event</option>
            <option value="2-add-feed">2 Additional Event</option>
            <option value="3-add-feed">3 Additional Event</option>
          </select> */}
          
            {/* {additionalFeed === '1-add-feed' && (
              <div className='add-1-feed'>
                <label htmlFor="1-add-event-duration">Event Duration</label>
                <EventDurationPicker onDateRangeChange={handleDurationChange} />
      
                <label htmlFor="1-add-guests">Number of Guests</label>
                <select id="1-add-guests" value={guests} onChange={handleGuestsChange}>
                  <option value="50-guests">50+ Guests</option>
                  <option value="100-guests">100+ Guests</option>
                  <option value="200-guests">200+ Guests</option>
                  <option value="300-guests">300+ Guests</option>
                </select>
              </div>
            )} */}
          
        </form>

        <div className="action-buttons">
          <button className="btn request-info" onClick={handleRequestInfo}>
            Request Info
          </button>
          <button className="btn buy-now" 
            onClick={handleProcessPayment} 
            disabled={loading || !dateRange.startDate || !dateRange.endDate}
          >
            {orderId ? 'Purchased!' : 'Buy Now'}
          </button>
        </div>

        {orderId && (
          <div className='order-details'>
            <h1>Thank you for your order!</h1>
            <h2>Order Confirmation: {orderId}</h2>
            {productOptions && (
              <div className="details">
                <p>Dates: {dateRange.startDate} - {dateRange.endDate}</p>
                <p>Duration: {durationLabels[productOptions.duration]}</p>
                <p>Number of Guests: {guestsLabels[productOptions.guests]}</p>
                <p>Price: ${productOptions.price}</p>
              </div>
            )}
            <h3>We'll be in touch!</h3>
          </div>
        )}

        {showForm && (<RequestInfoForm onClose={handleCloseForm}/>)}

      {/* {showPayment && (<QuickPayCheckoutButton price={price}/>)} */}

      </section>

      <section className='product-features'>
        <div className="included-features">
          <h3>What comes with the package?</h3>
          <ul>
            <li>A private event platform for you and your guests</li>
            <li>A custom QR code for easy access</li>
            <li>Scheduled prompts, event updates and reminders</li>
            <li>Unlimited SMS worldwide</li>
            <li>Vintage filter on instant capture uploads</li>
            <li>Photo, video and voice uploads</li>
            <li>Host access to the Event Hub</li>
            <li>A photo album delivered after the event</li>
          </ul>
        </div>
      </section>
    </main>
  );
};

export default BookNow;
